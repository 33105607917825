export const UnderConstruction = (): JSX.Element => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    };
    return (
        <div style={style}>
            <div>
                <h1>Deanrivers.io</h1>
                <p>Under Construction</p>
            </div>
        </div>
    );
};
