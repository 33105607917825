// import FadeIn from 'react-fade-in/lib/FadeIn';
import { Card } from '../../components/Card';
import { Grid } from '../../components/Grid';
import { GridItem } from '../../components/GridItem';
import { PageHeader } from '../../components/PageHeader';
import { PageLayout } from '../../components/PageLayout';

const projects: {
	title: string;
	description: string;
	iconUrls: string[];
	url: string;
}[] = require('./portfolio.json');

export const Portfolio = (): JSX.Element => {
	return (
		<PageLayout>
			<div className='portfolio'>
				<PageHeader text='Portfolio' slashColor='green' />
				<Grid>
					<>
						{projects.map((project, index: number) => {
							return (
								<GridItem xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
									<a href={project.url} target='_blank' rel='noreferrer' className='cardLink'>
										<Card>
											<h2 className='cardHeader'>{project.title}</h2>
											<Grid>
												<>
													{project.iconUrls.map((url, urlIndex) => {
														return (
															<GridItem xs={2} key={urlIndex}>
																<img alt={url} width={'100%'} src={url} />
															</GridItem>
														);
													})}
												</>
											</Grid>
											<p className='cardDescription'>{project.description}</p>
										</Card>
									</a>
								</GridItem>
							);
						})}
					</>
				</Grid>
			</div>
		</PageLayout>
	);
};
