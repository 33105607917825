import { ChangeEvent } from 'react';

interface TextInputFieldProps {
	value: string;
	placeholder?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TextInputField = (props: TextInputFieldProps): JSX.Element => {
	return (
		<div className='textInputField'>
			<input type='text' placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
		</div>
	);
};
