import { MouseEventHandler } from 'react';

interface ButtonProps {
	text: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
	disabled: boolean;
}

export const Button = (props: ButtonProps): JSX.Element => {
	return (
		<div className='button'>
			<button disabled={props.disabled} onClick={props.onClick}>
				{props.text}
			</button>
		</div>
	);
};
