import { useState, useEffect } from 'react';
import { Grid } from '../../components/Grid';
import { GridItem } from '../../components/GridItem';
import { PageHeader } from '../../components/PageHeader';
import { PageLayout } from '../../components/PageLayout';
import { TextInputField } from '../../components/TextInputField';
import { TextInputArea } from '../../components/TextInputArea';
import { Button } from '../../components/Button';
import { emailTest } from '../../utils/regex';
import { sendMessage } from '../../utils/api';

interface FormData {
	name: string;
	email: string;
	content: string;
}

const initialFormData: FormData = {
	name: '',
	email: '',
	content: '',
};

export const Contact = (): JSX.Element => {
	const [formData, setFormData] = useState<FormData>(initialFormData);
	const [formDataValid, setFormDataValid] = useState<boolean>(false);
	const [messageSent, setMessageSent] = useState<boolean>(false);

	useEffect(() => {
		console.log(formData);

		if (formData.name && emailTest(formData.email) && formData.content) {
			setFormDataValid(true);
		} else setFormDataValid(false);
	}, [formData]);

	const handleSubmit = (): void => {
		sendMessage(formData).then((res) => {
			console.log('res', res);
			if (res.status === 'success') {
				setMessageSent(true);
			} else {
				alert(`There was an error sending your message: ${JSON.stringify(res)}`);
			}
		});
	};

	const MessageSentRender = (): JSX.Element => {
		return (
			<GridItem xs={12}>
				<p>Thank you. Your message has been sent.</p>
			</GridItem>
		);
	};

	const ContactFormrender = (): JSX.Element => {
		return (
			<>
				<GridItem xs={12}>
					<TextInputField
						value={formData.name}
						placeholder='Name'
						onChange={(e: any) =>
							setFormData((prev) => {
								prev = { ...prev };
								prev.name = e.target.value;
								return prev;
							})
						}
					/>
				</GridItem>
				<GridItem xs={12}>
					<TextInputField
						value={formData.email}
						placeholder='Email'
						onChange={(e: any) =>
							setFormData((prev) => {
								prev = { ...prev };
								prev.email = e.target.value;
								return prev;
							})
						}
					/>
				</GridItem>
				<GridItem xs={12}>
					<TextInputArea
						value={formData.content}
						placeholder='Message'
						onChange={(e: any) =>
							setFormData((prev) => {
								prev = { ...prev };
								prev.content = e.target.value;
								return prev;
							})
						}
					/>
				</GridItem>
				<GridItem xs={12}>
					<Button text='Submit' disabled={!formDataValid} onClick={handleSubmit} />
				</GridItem>
			</>
		);
	};

	return (
		<PageLayout>
			<div className='contact'>
				<PageHeader text='Contact' slashColor='blue' />
				<Grid>
					<>{messageSent ? MessageSentRender() : ContactFormrender()}</>
				</Grid>
			</div>
		</PageLayout>
	);
};
