import { PageHeader } from '../../components/PageHeader';
import { PageLayout } from '../../components/PageLayout';

export const Home = (): JSX.Element => {
	return (
		<PageLayout>
			<div className='home'>
				<PageHeader text='Home' slashColor='red' />
				<div>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.
						Donec in efficitur leo. In hac habitasse platea dictumst. Sed ullamcorper, nunc a varius
						dignissim, orci est laoreet odio, eu tincidunt leo nisl et nulla. Fusce mollis imperdiet
						interdum. Integer posuere, ex nec congue consequat, nibh purus feugiat elit, ac facilisis mauris
						nunc a arcu. Vivamus quis vehicula nisl. Duis ut maximus neque. Suspendisse potenti. Curabitur
						lacinia mi vitae ullamcorper varius. Sed dui metus, auctor a viverra vel, maximus viverra velit.
						Sed vitae tortor metus. Nullam vehicula convallis nunc, ut pulvinar urna accumsan quis. Fusce
						dapibus purus vitae augue dictum, nec pellentesque nulla sollicitudin. Sed sapien mi, ultricies
						non est in, aliquet gravida nunc. Aliquam erat volutpat. Vivamus vel quam nec nulla gravida
						euismod vitae at nunc. Fusce convallis feugiat efficitur. Nam accumsan libero nec nibh
						hendrerit, non elementum metus mollis. Pellentesque tincidunt, purus sit amet tincidunt iaculis,
						velit orci porta nisi, non fringilla ante augue eu metus.
					</p>
				</div>
			</div>
		</PageLayout>
	);
};
