import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AppContainer } from './components/AppContainer';
import { Portfolio } from './routes/Portfolio/Portfolio';
import { Home } from './routes/Home/Home';
import { Contact } from './routes/Contact/Contact';
import { UnderConstruction } from './components/UnderConstruction';

function App() {
    const ScrollToTop: React.FC = () => {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, [location]);

        return null;
    };

    return (
        <UnderConstruction /> || (
            <>
                <BrowserRouter>
                    <ScrollToTop />
                    <AppContainer>
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/home' element={<Home />} />
                            <Route path='/portfolio' element={<Portfolio />} />
                            <Route path='/contact' element={<Contact />} />
                            {/* Catch-all route */}
                            {/* <Route path='*' element={<RedirectToHome />} /> */}
                        </Routes>
                    </AppContainer>
                </BrowserRouter>
            </>
        )
    );
}

export default App;
