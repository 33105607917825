import { NavLink } from 'react-router-dom';

export const AppContainer = ({ children }: any): JSX.Element => {
	const Nav = (): JSX.Element => {
		return (
			<div className='nav'>
				<ul className='nav-list'>
					<li className='nav-item'>
						<NavLink to='/home'>
							<span className='slash' style={{ color: 'red' }}>
								/
							</span>
							Home
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink to='/portfolio'>
							<span className='slash' style={{ color: 'green' }}>
								/
							</span>
							Portfolio
						</NavLink>
					</li>

					<li className='nav-item'>
						<NavLink to='/contact'>
							<span className='slash' style={{ color: 'blue' }}>
								/
							</span>
							Contact
						</NavLink>
					</li>
				</ul>
			</div>
		);
	};

	return (
		<div className='appContainer'>
			<Nav />
			<div className='contentContainer'>{children}</div>
		</div>
	);
};
