interface PageHeaderProps {
	slashColor: string;
	text: string;
}

export const PageHeader = (props: PageHeaderProps): JSX.Element => {
	return (
		<div>
			<h1 className='pageHeader'>
				<span className='slash' style={{ color: props.slashColor }}>
					/
				</span>
				{props.text}
			</h1>
			<hr className='divider' />
		</div>
	);
};
