import { Footer } from './Footer';

interface PageLayoutProps {
	children: JSX.Element;
}

export const PageLayout = (props: PageLayoutProps): JSX.Element => {
	return (
		<div className='pageLayout'>
			{props.children}
			<Footer />
		</div>
	);
};
