import { ChangeEvent } from 'react';

interface TextInputAreaProps {
	value: string;
	placeholder?: string;
	maxLength?: number;
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextInputArea = (props: TextInputAreaProps): JSX.Element => {
	return (
		<div className='textInputArea'>
			<textarea
				maxLength={props.maxLength}
				value={props.value}
				placeholder={props.placeholder}
				onChange={props.onChange}
			/>
		</div>
	);
};
