/**
 * Determines if email is valid.
 * @param {string} email - The email string being passed in.
 * @return {boolean} - The regex test result.
 */
export const emailTest = (email: string): boolean => {
	const regex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
	return regex.test(email);
};

/**
 * Determines if password is valid.
 * 8 letter password, with at least a symbol, upper and lower case letters and a number.
 * @param {string} password - The password string being passed in.
 * @return {boolean} - The regex test result.
 */
export const passwordTest = (password: string): boolean => {
	const regex: RegExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
	return regex.test(password);
};
