let apiBaseUrl = '';
if (process.env.NODE_ENV === 'production') {
	apiBaseUrl = 'https://us-central1-website-75fd6.cloudfunctions.net/apiDeanPersonalWebsiteProduction';
} else {
	apiBaseUrl = 'http://localhost:5001/website-75fd6/us-central1/apiDeanPersonalWebsiteProduction';
}

export const sendMessage = (message: { name: string; email: string; content: string }) => {
	console.log('message from frontend: ', message);
	return fetch(`${apiBaseUrl}/message`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(message),
	})
		.then((res) => {
			if (!res.ok) {
				throw new Error(`Server responded with status code ${res.status}`);
			}
			return res.json();
		})
		.catch((error) => console.error(error));
};
