interface GridItemProps {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	children: JSX.Element;
}

export const GridItem = (props: GridItemProps): JSX.Element => {
	const sizeClass = `grid-span-${props.xs || 12}`;
	const smClass = props.sm ? `sm:grid-span-${props.sm}` : '';
	const mdClass = props.md ? `md:grid-span-${props.md}` : '';
	const lgClass = props.lg ? `lg:grid-span-${props.lg}` : '';
	const xlClass = props.xl ? `xl:grid-span-${props.xl}` : '';
	return <div className={`${sizeClass} ${smClass} ${mdClass} ${lgClass} ${xlClass}`}>{props.children}</div>;
};
